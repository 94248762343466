<template>
  <div class="index my-booking">
    <van-nav-bar title="我的预约" left-arrow @click-left="onBack" />
    <div v-if="bookings.length > 0">
      <div class="booking" v-for="booking in bookings" :key="booking.id">
        <div class="rest-name" @click="onRestaurant(booking.restaurantId)" >
          <van-icon name="shop-o" />
          {{ booking.restaurantName }} {{ booking.restaurantCookingStyle }} 
          ¥{{booking.restaurantPricePerPerson}}/人
          <van-icon name="arrow" />
          <span class="status">{{getStatus(booking)}}</span>
        </div>
        <van-row @click="onRestaurantBooking(booking.id)">
          <van-col span="8">
            <van-image :src="booking.restaurantImageUrl" width="95%" height="92px" />
          </van-col>
          <van-col span="15" offset="1">
            <div class="detail">
              <div class="name">{{ booking.restaurantName }}</div>
              <div class="info">
                <span class="time">{{booking.restaurantAddress}}</span>
              </div>
              <div class="info">
                <span>预定人数: </span> <span class="time">{{booking.personNumber}}</span>
              </div>
              <div class="info" v-if="!booking.verified">
                <span>预定时间: </span> <span class="time">{{getBookingTime(booking)}}</span>
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
    <van-empty description="您还没有相关预约" v-if="bookings.length == 0" />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { parseTime } from "../../utils/index"
import BookingService from "../../api/restaurant-booking-service";
import "../../css/my-bookings.scss";

export default {
  components: {},
  setup() {
    const state = reactive({
      bookings: [],
    });
    const router = useRouter();
    const onBack = () => router.push("/my");
    const onRestaurant = (id) => router.push("/restaurant/" + id);
    const onRestaurantBooking = (id) => router.push("/restaurant-booking-details/" + id);
    const getStatus = booking => {
      if( booking.status == 'USER_BOOKING') {
        return "已申请预约"
      } else if( booking.status == 'USER_CANCEL') {
        return "已取消预约"
      } else if( booking.status == 'RESTAURANT_CONFIRM') {
        return "已确定预约"
      } else if( booking.status == 'RESTAURANT_REJECT') {
        return "已拒绝预约"
      } else if( booking.status == 'RESTAURANT_CANCEL') {
        return "已取消预约"
      } else {
        return "";
      }
    }
    const getBookingTime = booking => {
        return parseTime(new Date(booking.bookingTime), "{y}-{m}-{d} {h}:{i}")
    }
    onMounted(() =>
      BookingService.getRestaurantBookingRequests().then(
        (bookings) => (state.bookings = bookings)
      )
    );
    return {
      ...toRefs(state),
      onBack,
      onRestaurant,
      onRestaurantBooking,
      getStatus,
      getBookingTime
    };
  },
};
</script>
